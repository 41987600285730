.mobi-competitions{
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;    
    overflow: scroll;
    padding: 5px;
    margin-bottom: 3px;
}

.mobi-competition-item{
  margin: 0 3px;
  border-radius: 5px;
  padding: 7px;
  flex-shrink: 0;
  background-image: linear-gradient(to right,rgba(105, 112, 206, 0.4),rgba(59, 69, 212, 0.4));
  font-size: 15px;  
  font-weight: 400;
}

.mobi-competition-item:hover{
    cursor: pointer;
    background-color: rgba(59, 69, 212, 0.4);
  }

.font-arial{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
}

@media screen and (max-width: 600px) {   
    .competition-accordion{
        display:none;
    }

    .mobi-competitions{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;    
        overflow: scroll;
        padding: 5px;
        margin-bottom: 8px;
    }
    
   
  }