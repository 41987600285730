.playable-event-accordion-header .accordion-button{
    padding: 8px;
}

.playable-event-accordion-header span{
    font-size: 14px;
}

.accordion-button:not(.collapsed){
    color: black;
}