
.event-question{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 400;
}
.choice-card:hover{
    transform:translate(0px,5px);
}

.playable-event-choice{
    width:100px;
    border: 2px solid blue;
}

.playable-event-desc{
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 10px 0;
}

.playable-event-desc-item{
    margin: 0 5px;
    color: gray;
    font-size: 15px;
}
.event-choice-container{
    margin:auto;
}

@media screen and (max-width:600px) {
    .event-question{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 16px;        
    }
    .closing-time{
        font-size: 12px;
    }
}
