body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;   
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

.link:hover{
  cursor: pointer;
  text-decoration: underline;
  color: rgb(20, 139, 20);
}

.pointer:hover{
  cursor: pointer;
}
.margin-auto{
  margin:auto;
}




