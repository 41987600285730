.competition-name{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    
  
}

.card-header{
    background-image: linear-gradient(to right,rgb(170, 177, 194),rgb(148, 197, 219));
}

