.profile-image:hover{
    cursor: pointer;
}

.message-card{
    padding:5px;
    border: 1px solid grey;
    margin: 10px 0;
}

.message-subject{
    font-weight: bold;
}