header{
    background-image: linear-gradient(to right,rgb(150, 169, 211),rgb(105, 141, 189));
}

.brand-text{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    color:white;
    font-size: 25px;
    text-shadow: 5px 5px #3d91c9;
}
.brand-sentence-text{
  font-size: 14px;
  color: #db8bc1;
}
.points{
    padding: 0px 0px;
    margin: 0 5px;
    
}
.user-points-text{
    position: relative;
    top:5px;
    font-family:'Times New Roman', Times, serif;
    font-size: 25px;
    font-weight: bold;
    color: white;    
}

.login-text, .register-text{
    color: white;
    font-size: 13px;
    font-weight: bold;
 
}
.login-text{
    padding: 5px;
    background-color: rgb(194, 45, 45);
    border-radius: 5px;
}

.nav-btn a{
    font-family: Verdana, Geneva, Tahoma, sans-serif;    
 
}

.nav-dropdown{
    text-decoration: none;
    color:rgb(255, 255, 255);
    z-index: 5000;
}
#basic-nav-dropdown{
    color: white;
}

.nav-item .nav-link{
    color:rgb(12, 11, 11);
    font-weight: bold;
}

.nav-item .nav-link:hover{
    color:rgb(136, 135, 135);
}

.offcanvas-dropdown{
    text-decoration: none;   
    z-index: 5000;
   
}
.offcanvas-dropdown .offcanvas-body{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; 
    background-image: linear-gradient(to right,rgb(180, 197, 206),rgb(108, 153, 196)); 
    
}
.offcanvas-dropdown .offcanvas-body .nav-btn .nav-link{
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.nv-links{
    display: flex;
    justify-content: center;
    list-style-type: none;
}

.nv-item{
    display: inline;
    padding: 5px;
    margin: 5px; 
}


.nv-item a{
    text-decoration: none;
}

.button-group{
    float: right;
}

.top-nav{
     width:100vw;
     padding: 5px;
     background-image: linear-gradient(to right, rgba(255,0,0,0), rgb(84, 91, 161));
}

.top-nav-items{
    display: flex;
    justify-content: space-around;
}
.mid-nav-items{
    display: flex;
    justify-content: center;
}

.top-nav-item{
    display: inline;
}


@media screen and (max-width: 600px) {
    .user-points-text{
        position: relative;
        font-family:'Times New Roman', Times, serif;
        font-size: 13px; 
        color: white;
        top:5px;       
    }
    .login-text, .register-text{
        color: white;
        font-size: 13px;
     
    }

    .nav-item .nav-link{
        color:rgb(54, 49, 49);       
    }

    .brand-sentence-text{
        display: none;
      }
    
  }
