@media screen and (max-width: 600px) {
   .footer-body{
    display: flex;
    flex-direction: column;
    justify-content: center;    
   }
   .copyright-text{
    text-align: center;
   }
   .footer-body .nav{
    display: flex;
    justify-content: center;    
   }
}

.footer{    
    background-color: rgb(226, 222, 222);
    position:relative;    
    padding: 5px; 
    height: 80vh;
  }

.footer-body{
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}  
